<template>
  <collapse-card :model-value="true">
    <template #title>
      <span class="mr-2 text-sm font-medium">
        Submission Details
      </span>
    </template>
    <template #cardContent>
      <div class="py-2 px-4">
        <base-input
          v-model="collaborationForm.submissionId"
          type="text"
          container-class="mb-4 flex items-center"
          disabled
          label="Submission ID"
          label-class="label-w-46"
        />
        <base-input
          v-model="collaborationForm.submission.shortArticleDescription"
          type="text"
          container-class="mb-4 flex items-center"
          disabled
          label="Short Article Description"
          label-class="label-w-46"
        />
        <base-input
          v-model="collaborationForm.submission.styleGuideName"
          type="text"
          container-class="mb-4 flex items-center"
          disabled
          label="Style Guide"
          label-class="label-w-46"
        />
        <base-input
          v-model="collaborationForm.submission.article"
          type="text"
          container-class="mb-4 flex items-center"
          disabled
          label="Article"
          label-class="label-w-46"
        />
        <base-input
          v-model="collaborationForm.submission.contractNumber"
          type="text"
          container-class="mb-4 flex items-center"
          disabled
          label="Contract Number"
          label-class="label-w-46"
        />
      </div>
    </template>
  </collapse-card>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import useCollaborationForm from './collaborationForm.js';

export default {
    name: 'SubmissionDetails',

    components: {
        CollapseCard: defineAsyncComponent(() => import('@/components/CollapseCard.vue')),
        BaseInput: defineAsyncComponent(() => import('@/components/generic-components/BaseInput.vue'))
    },

    setup (props) {
        const { collaborationForm } = useCollaborationForm();

        return {
            collaborationForm
        };
    }
};
</script>
